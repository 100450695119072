import React from 'react';
import plumbingImg from '../images/services/plumber-svgrepo-com.svg';
import plasteringImg from '../images/services/plaster.png';
import acImg from '../images/services/ac.png';
import tilingImg from '../images/services/tiling.png';
import paintingImg from '../images/services/paint.jpg';
import carpentryImg from '../images/services/carpenter.png';
import electricalImg from '../images/services/electrician_03.jpg';
import ceilingImg from '../images/services/full-shot-man-standing-ladder.svg';
import maintenanceImg from '../images/services/Maintenance-bro.svg';
import installationImg from '../images/services/Bug fixing-amico.svg';
import ventilationImg from '../images/services/Pipeline maintenance-bro.svg';
import renovationImg from '../images/services/Under construction-rafiki.svg';

const Services = () => {
    const services = [
        {
            img: plumbingImg,
            title: "Plumbing & Sanitary Installations",
            description: "Efficient plumbing and sanitary solutions tailored to residential, commercial, and industrial needs.",
        },
        {
            img: plasteringImg,
            title: "Plaster Works",
            description: "High-quality plastering services for durable, smooth, and aesthetic finishes.",
        },
        {
            img: acImg,
            title: "Air Conditioning & Maintenance",
            description: "Expert installation and maintenance of air-conditioning and ventilation systems for optimal comfort.",
        },
        {
            img: tilingImg,
            title: "Floor & Wall Tiling",
            description: "Precision tiling services to enhance the beauty and functionality of your spaces.",
        },
        {
            img: paintingImg,
            title: "Painting Contracting",
            description: "Professional painting services that bring vibrancy and protection to your property.",
        },
        {
            img: carpentryImg,
            title: "Carpentry & Wood Flooring",
            description: "Custom carpentry and wood flooring solutions for stylish and durable interiors.",
        },
        {
            img: electricalImg,
            title: "Electrical Fittings & Fixtures",
            description: "Safe and efficient electrical repair and maintenance services for your systems.",
        },
        {
            img: ceilingImg,
            title: "False Ceiling & Light Partitions",
            description: "Innovative ceiling designs and light partitions to enhance your interiors.",
        },
        {
            img: maintenanceImg,
            title: "General Maintenance",
            description: "Comprehensive maintenance services to keep your property in top condition.",
        },
        {
            img: installationImg,
            title: "Equipment Installation",
            description: "Reliable installation of technical equipment to ensure safety and performance.",
        },
        {
            img: ventilationImg,
            title: "Ventilation System Services",
            description: "Advanced ventilation solutions to improve air quality and energy efficiency.",
        },
        {
            img: renovationImg,
            title: "Renovation & Remodeling",
            description: "Tailored renovation and remodeling services to revamp and modernize your spaces.",
        },
    ];

    return (
        <div id="services" className="bg-gray-100 py-12">
            {/* Header Section */}
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Services</h2>
                    <div className="flex justify-center">
                        <div className="w-24 border-b-4 border-blue-900"></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        Comprehensive Solutions Tailored to Your Technical and Maintenance Needs.
                    </h2>
                </div>

                {/* Service Cards */}
                <div className="px-8" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className="bg-white transition-transform transform hover:scale-105 overflow-hidden text-gray-700 hover:text-white hover:bg-gray-500 rounded-lg shadow-lg p-5"
                            >
                                <div className="flex flex-col items-center">
                                    <img
                                        alt={service.title}
                                        className="h-36 w-36 object-contain mb-4"
                                        src={service.img}
                                    />
                                    <h2 className="font-bold text-xl text-center mb-2">{service.title}</h2>
                                    <p className="text-md text-center font-medium">{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Services;
