import React from 'react';
import dm from '../images/clients_portfolio/dm.png'
import eti from '../images/clients_portfolio/eti.png'
import dwa from '../images/clients_portfolio/dewa.png'
import adnoc from '../images/clients_portfolio/adnoc.jpeg'
import du from '../images/clients_portfolio/du.jpeg'
import dc from '../images/clients_portfolio/dcomerce.jpeg'

const clientLogos = [
    {
        name: "Dubai Municipality",
        img: dm,
    },
    {
        name: "Etisalat",
        img: eti,
    },
    {
        name: "DEWA",
        img: dwa,
    },
    {
        name: "ADNOC",
        img: adnoc,
    },
    {
        name: "DU Telecom",
        img: du,
    },
    {
        name: "Dubai Chamber of Commerce",
        img: dc,
    },
];

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100" id='clients'>
            <section data-aos="fade-up">
                {/* Header Section */}
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Clients</h2>
                    <div className="flex justify-center">
                        <div className="w-24 border-b-4 border-blue-900"></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        Trusted by Leading Organizations Across the UAE
                    </h2>
                </div>

                {/* Clients Grid */}
                <div className="px-8 py-16">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
                        {clientLogos.map((client, index) => (
                            <div
                                key={index}
                                className="flex justify-center items-center transition-transform transform hover:scale-105"
                            >
                                <img
                                    src={client.img}
                                    alt={client.name}
                                    className="h-24 w-auto opacity-70 hover:opacity-100 transition-opacity duration-300"
                                    title={client.name}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Clients;
