import React, { useState } from 'react';

const Portfolio = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const projects = [
        {
            title: "Residential Plumbing Installation",
            description: "Efficient water supply and drainage systems for a residential project.",
            details: "Installed over 50 pipelines with advanced filtration systems, ensuring long-term durability.",
        },
        {
            title: "Luxury Apartment Plastering",
            description: "Smooth and durable plaster finishes for walls and ceilings.",
            details: "Covered 2000 sq. ft. of walls using premium materials to achieve a luxurious finish.",
        },
        {
            title: "Office Air Conditioning Setup",
            description: "Optimized air-conditioning and ventilation systems for better air quality.",
            details: "Installed 15 energy-efficient AC units for a corporate office, reducing operational costs.",
        },
        {
            title: "Kitchen Tiling Renovation",
            description: "Modern ceramic tiles for an aesthetic and functional kitchen space.",
            details: "Installed 500 sq. ft. of tiles, blending sleek designs with durability.",
        },
        {
            title: "Retail Space Painting",
            description: "Vibrant painting solutions for a retail shop interior.",
            details: "Used eco-friendly paint across 3000 sq. ft., creating a welcoming and fresh ambiance.",
        },
        {
            title: "Custom Carpentry Work",
            description: "Handcrafted woodwork for interiors, including cabinets and flooring.",
            details: "Created oak wood cabinets and flooring, combining functionality with elegance.",
        },
        {
            title: "Industrial Electrical Maintenance",
            description: "Comprehensive electrical repairs for a safe and efficient operation.",
            details: "Serviced over 100 industrial circuits, ensuring compliance with safety standards.",
        },
        {
            title: "False Ceiling Installation",
            description: "Innovative ceiling designs for a retail outlet.",
            details: "Installed false ceilings with integrated LED lighting to enhance aesthetics.",
        },
        {
            title: "Hotel Renovation Project",
            description: "Complete interior renovation for a boutique hotel.",
            details: "Executed painting, tiling, and carpentry work to modernize the hotel's interiors.",
        },
        {
            title: "Warehouse Ventilation System",
            description: "Improved airflow for an industrial warehouse.",
            details: "Set up high-capacity ventilation fans to optimize air circulation and temperature control.",
        },
        {
            title: "Commercial HVAC Installation",
            description: "Advanced heating, ventilation, and air conditioning for a commercial complex.",
            details: "Installed smart HVAC systems to regulate temperature and improve energy efficiency.",
        },
        {
            title: "Restaurant Floor Tiling",
            description: "Durable and stylish floor tiles for a high-traffic restaurant.",
            details: "Used anti-slip porcelain tiles, ensuring safety and style for a busy dining area.",
        },
    ];

    const handleExpandCard = (index) => {
        setExpandedCard(expandedCard === index ? null : index);
    };

    return (
        <div id="portfolio" className="bg-gray-50 py-16">
            {/* Header Section */}
            <div className="text-center mb-12">
                <h2 className="text-4xl font-extrabold text-blue-900 uppercase">Portfolio</h2>
                <div className="mt-4 w-24 mx-auto border-b-4 border-blue-900"></div>
                <p className="mt-4 text-lg text-gray-600">
                    Explore our completed projects that highlight our expertise and dedication to quality.
                </p>
            </div>

            {/* Project Cards */}
            <div className="px-8">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projects.map((project, index) => (
                        <div
                            key={index}
                            className={`bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${
                                expandedCard === index ? 'lg:col-span-3' : ''
                            }`}
                        >
                            <div className="p-6">
                                <h4 className="text-2xl font-semibold text-blue-900 mb-4">{project.title}</h4>
                                <p className="text-gray-600 mb-6">{project.description}</p>
                                {expandedCard === index && (
                                    <p className="text-gray-500 mt-4">{project.details}</p>
                                )}
                                <div
                                    className={`text-center ${
                                        expandedCard === index ? 'mt-6' : 'mt-4'
                                    }`}
                                >
                                    <button
                                        onClick={() => handleExpandCard(index)}
                                        className="inline-block bg-blue-900 text-white font-medium py-2 px-6 rounded-lg hover:bg-blue-700 transition-colors"
                                    >
                                        {expandedCard === index ? 'Show Less' : 'Get Details'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
